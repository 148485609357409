import { useContext, useMemo } from 'react'
import DepartureSection from './price-overview/departure-section'
import { DepartureContext } from '../contexts/departure'
import { useTranslation } from 'react-i18next'
import CabinSection from './price-overview/cabin-section'
import ProductSection from './price-overview/product-section'
import { PassengersContext } from '../contexts/passengers'
import VoucherSection from './price-overview/voucher-section'
import { useCurrencyFormatter } from '../hooks/formatter'
import { usePriceTotal } from '../hooks/products'
import OverviewBlock from './price-overview/block'
import SGRSection from './price-overview/sgr-section'
import { ProductsContext } from '../contexts/products'
import { InsurancePolicy } from '../services/departures'
import { Market } from '../types/booking'
import { calculatePolicyCost } from '../utils/insurance'

interface PriceOverviewProps {
  additionalProducts: Record<string, number>
  insurance: Record<InsurancePolicy['insurance_type'], boolean>
  market: Market
  noInsurance: boolean
}

export default function PriceOverview ({ additionalProducts, insurance, market, noInsurance }: PriceOverviewProps): JSX.Element {
  const { departure } = useContext(DepartureContext)
  const { t } = useTranslation()
  const formatter = useCurrencyFormatter()
  const { validated } = useContext(PassengersContext)
  const { products } = useContext(ProductsContext)

  const {
    totalPrice: totalPriceWithoutSGR,
    localCosts,
    numberOfPassengers
  } = usePriceTotal({
    cabins: true,
    discount: true,
    additionalProducts,
    bikes: true,
    diets: true,
    other: true,
    days: 8
  })

  const sgr = market !== 'north_america'
  const sgrCost = sgr ? products.sgr.unit_price * numberOfPassengers : 0

  const totalPrice = useMemo(
    () => totalPriceWithoutSGR + sgrCost,
    [totalPriceWithoutSGR, sgrCost])

  const vat = market !== 'north_america'
  const vatAmount = useMemo(() => totalPrice - totalPrice / 1.09, [totalPrice])

  const selectedPolicies = useMemo(() => {
    return products.insurance_policies.filter(policy => policy.market === market && insurance[policy.insurance_type] && !noInsurance)
  }, [products.insurance_policies, market, insurance, noInsurance])

  const selectedPolicyCosts = useMemo(() => {
    return selectedPolicies.map(policy => calculatePolicyCost(policy, {
      totalPrice: totalPriceWithoutSGR, localCosts
    }))
  }, [selectedPolicies, totalPriceWithoutSGR, localCosts])
  const totalPolicyCost = useMemo(() => selectedPolicyCosts.reduce((a, b) => a + b, 0), [selectedPolicyCosts])

  return (
    <>
      <h2 className='text-brand-primary text-lg font-bold mb-4'>{t('price_overview', 'Price overview')}</h2>
      <section className='bg-brand-well mb-8'>
        <DepartureSection departure={departure} />
        <CabinSection />
        {validated !== undefined && <ProductSection passengerInformation={validated} additionalProducts={additionalProducts} />}
        <VoucherSection />
        {sgr && <SGRSection numberOfPassengers={numberOfPassengers} sgrProduct={products.sgr} />}
        {vat && <OverviewBlock className='text-sm' label={t('vat', 'VAT (9% included)')} value={formatter.format(vatAmount)} />}
        {selectedPolicyCosts.length > 0 && (
          <>
            <OverviewBlock className='text-xl' label={t('subtotal', 'Subtotal')} value={<span className='text-black'>{formatter.format(totalPrice)}</span>} />
            <OverviewBlock className='text-sm' label={t('insurance', 'Insurance')} value={formatter.format(totalPolicyCost)} />
          </>
        )}
        <OverviewBlock className='text-xl' label={t('total', 'Total')} value={<span className='text-black'>{formatter.format(totalPrice + totalPolicyCost)}</span>} />
      </section>
    </>
  )
}

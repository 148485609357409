import { useContext, useMemo } from 'react'
import { TourContext } from '../contexts/tour'
import { DepartureContext, DepartureContextType } from '../contexts/departure'
import { useTranslation } from 'react-i18next'
import dayjs from '../libs/dayjs'
import { CabinsContext } from '../contexts/cabins'
import {
  useCabinPricing,
  useFullCabinSelection,
  useNumberOfPassengers
} from '../hooks/cabin-selection'
import SummaryBlock from './summary/block'
import CabinPriceBlock from './summary/cabin-price-block'
import { SerializedPassengerInformation } from '../types/passenger-information'
import ProductsBlock from './summary/products-block'
import { VoucherContext } from '../contexts/voucher'
import VoucherBlock, { VoucherIndicationBlock } from './summary/voucher-block'
import { usePassengerProducts } from '../hooks/products'
import { useAllVouchers, useDiscounts } from '../hooks/vouchers'
import { useCurrencyFormatter } from '../hooks/formatter'
import { ProductsContext } from '../contexts/products'
import { ProductsResponse } from '../services/departures'
import { useOptionalContext } from '../hooks/optional-context'
import InformationIcon from './information-icon'
import { hasSGR } from '../utils/products'
import { AdditionalProducts } from '../types/products'

export interface BookingSummaryProps {
  date?: dayjs.Dayjs
  passengerInformation?: SerializedPassengerInformation
  additionalProducts?: AdditionalProducts
}

function TourSummaryBlock (): JSX.Element | undefined {
  const { t } = useTranslation()
  const { tour } = useOptionalContext(TourContext) ?? { tour: undefined }
  const { departure } = useOptionalContext(DepartureContext) ?? { departure: undefined }

  const tourName = departure?.tour_name ?? (tour !== undefined ? tour.tours[0].name : undefined)
  return tourName !== undefined ? (<SummaryBlock label={t('summary:tour', 'Tour')} value={tourName} />) : undefined
}

function DepartureBlock ({ date }: Pick<BookingSummaryProps, 'date'>): JSX.Element | undefined {
  const { t, i18n } = useTranslation()
  const { departure } = useContext(DepartureContext) as Partial<DepartureContextType>
  const { tour: { tours: [tour] } } = useContext(TourContext)
  const locale = useMemo(() => i18n.resolvedLanguage ?? 'en', [i18n.resolvedLanguage])

  const departureDate = useMemo(() => {
    const dateFormat = t('date_format', 'L')
    if (departure === undefined) {
      if (date === undefined) {
        return undefined
      }

      const calculatedEnd = date.locale(locale).add(tour.duration - 1, 'days')
      return `${date.format(dateFormat)} - ${calculatedEnd.format(dateFormat)}`
    }
    const start = dayjs(departure.date).locale(locale)
    const end = dayjs(departure.end_date).locale(locale)
    return `${start.format(dateFormat)} - ${end.format(dateFormat)}`
  }, [t, departure, locale, date, tour.duration])

  return (
    <>
      {departureDate !== undefined && (<SummaryBlock label={t('summary:date', 'Date')} value={departureDate} />)}
      {departure !== undefined && (
        <>
          <SummaryBlock label={t('summary:ship', 'Ship')} value={departure.ship_name} />
          <SummaryBlock label={t('summary:port_of_departure', 'Port of departure')} value={departure.port_of_departure_name} />
        </>
      )}
    </>
  )
}

function SGRBlock ({ numberOfPassengers, sgrProduct }: { numberOfPassengers: number, sgrProduct: ProductsResponse['sgr'] }): JSX.Element {
  const formatter = useCurrencyFormatter()
  const { t } = useTranslation()
  return (
    <SummaryBlock className='bg-brand-well-dark text-sm'>
      <strong className=' flex items-center'>
        {numberOfPassengers}&times; {sgrProduct.name}
        {sgrProduct.description !== undefined && <InformationIcon description={sgrProduct.description} />}
      </strong>
      <span>{t('price_per_person', '{{ price }}\u00A0p.p.', { price: formatter.format(sgrProduct.unit_price) })}</span>
    </SummaryBlock>
  )
}

function PricingBlock ({ passengerInformation }: Pick<BookingSummaryProps, 'passengerInformation'>): JSX.Element | undefined {
  const { t } = useTranslation()
  const formatter = useCurrencyFormatter()
  const { cabinTypeIds = [] } = useOptionalContext(CabinsContext) ?? {}
  const { departure } = useOptionalContext(DepartureContext) ?? {}
  const { tour: { tours: [tour] } } = useContext(TourContext)
  const { voucher } = useContext(VoucherContext)
  const cabinSelection = useFullCabinSelection(cabinTypeIds)

  const { totalCabinPrice, totalPassengers } = useCabinPricing({ cabinSelection })
  const vouchers = useAllVouchers(departure, voucher)
  const { discount } = useDiscounts(totalCabinPrice, totalPassengers, vouchers)
  const { passengerProductsTotalPrice } = usePassengerProducts({
    passengerInformation: passengerInformation ?? { passengers: [] },
    bikes: true,
    diets: true,
    other: true,
    days: departure?.duration ?? tour.duration
  })
  const numberOfPassengers = useNumberOfPassengers(cabinSelection)
  const { products } = useOptionalContext(ProductsContext) ?? {}

  const sgr = products !== undefined && hasSGR(departure, passengerInformation)
  const sgrCost = sgr ? products.sgr.unit_price * numberOfPassengers : 0

  const totalPrice = useMemo(() => sgrCost + passengerProductsTotalPrice + totalCabinPrice - discount, [discount, passengerProductsTotalPrice, sgrCost, totalCabinPrice])

  if (Object.values(cabinSelection).flat().length === 0) {
    if (departure?.voucher != null) {
      return (
        <VoucherIndicationBlock voucher={departure.voucher} />
      )
    }
    return undefined
  }

  return (
    <>
      <CabinPriceBlock cabinSelection={cabinSelection} />
      {passengerInformation !== undefined && (
        <>
          <ProductsBlock passengerInformation={passengerInformation} label={t('summary:bikes', 'Bikes')} bikes />
          <ProductsBlock passengerInformation={passengerInformation} label={t('summary:additional_products', 'Additional products')} diets other />
        </>
      )}
      {(departure?.voucher != null || voucher != null) && <VoucherBlock departure={departure} cabinSelection={cabinSelection} voucher={voucher} />}
      {sgr && numberOfPassengers > 0 && (<SGRBlock numberOfPassengers={numberOfPassengers} sgrProduct={products.sgr} />)}
      <SummaryBlock className='text-lg' label={t('total', 'Total')} value={<span className='text-black'>{formatter.format(totalPrice)}</span>} />
    </>
  )
}

export default function BookingSummary ({ date, passengerInformation }: BookingSummaryProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <div>
      <h2 className='text-brand-primary font-bold text-lg mb-4'>{t('summary:booking_summary', 'Booking summary')}</h2>
      <aside className='bg-brand-well text-base mb-8'>
        <TourSummaryBlock />
        <DepartureBlock date={date} />
        <PricingBlock passengerInformation={passengerInformation} />
      </aside>
    </div>
  )
}

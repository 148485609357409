import { useTranslation } from 'react-i18next'
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { TourContext } from '../contexts/tour'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import dayjs from '../libs/dayjs'
import { useDepartures } from '../hooks/departures'
import BookingProgress from '../components/booking-progress'
import { Departure } from '../services/departures'
import Button from '../components/button'
import DepartureProvider from '../contexts/departure'
import { useShipTranslations } from '../hooks/translations'
import { cleanHTML } from '../utils/html'
import TwoColumnLayout from '../layouts/columns'
import { usePreloadQuery } from '../hooks/preload'
import {
  CabinTranslationsQuery, ProductTranslationsQuery
} from '../queries/translations'
import Sidebar from '../components/sidebar'
import Loader from '../components/loader'
import { WithError } from '../components/with-error'
import { useCurrencyFormatter } from '../hooks/formatter'
import { z } from 'zod'
import { filterDepartures } from '../utils/departures'

interface ShipCardProps {
  departure: Departure
  selected: boolean
  onSelect?: (departure: Departure) => void
  showSelected?: boolean
}

function ShipCard ({ departure, selected, onSelect, showSelected = false }: ShipCardProps): JSX.Element {
  const shipData = useShipTranslations(departure.ship_id)
  const { t } = useTranslation()
  const formatter = useCurrencyFormatter()
  const date = useMemo(() => dayjs(departure.date), [departure])
  const shipImages = [shipData?.image_url, shipData?.image_url1, shipData?.image_url2, shipData?.image_url3].filter((url): url is string => url != null)
  return (
    <div className='bg-brand-well border-t-double'>
      <div className='p-4 border-b flex justify-between'>
        <span className='flex gap-2 items-center'>
          <strong className='text-lg'>{departure.ship_name}</strong>
          {departure.voucher != null && <span className='text-sm px-2 py-1 rounded-xl bg-brand-discount text-white'>{(departure.voucher.discount_type === 'Discount Percentage' ? `-${departure.voucher.percentage}%` : t('price_per_person', { price: `${formatter.format(-departure.voucher.amount)}` }))}</span>}
        </span>
        {showSelected && (selected ? (<Button tone='primary'>{t('chosen_ship', 'Chosen ship')}</Button>) : (<Button tone='primary' outline onClick={() => onSelect?.(departure)}>{t('choose_ship', 'Choose ship')}</Button>))}
      </div>
      <div className='grid p-4 grid-cols-1 gap-8'>
        <div className='flex-1'>
          <strong>{t('departure_date', 'Departure date')} - {date.format(t('date_format', 'L'))}
          </strong>
          <div
            className='prose'
            dangerouslySetInnerHTML={{ __html: cleanHTML(shipData?.description ?? '') }}
          />
        </div>
        <div
          className='flex justify-center bg-white p-2 gap-1 flex-col'
        >
          <span>{t('depart_from', 'Depart from')}: <strong>{departure.port_of_departure_name ?? t('unknown', 'Unknown')}</strong></span>
          <span>{t('arriving_at', 'Arriving at')}: <strong>{departure.port_of_arrival_name ?? t('unknown', 'Unknown')}</strong></span>
        </div>
        {shipImages.length > 0 && (
          <div className=''>
            <div className='flex gap-4 overflow-y-auto snap-x snap-mandatory md:snap-none rounded'>
              {shipImages.map((url, index) => (
                <img
                  src={url} alt={`Image ${index} of the ship`} key={index}
                  className='aspect-[7/5] object-cover rounded md:h-[12rem] snap-start'
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function OptionalDepartureProvider ({ departure, children }: PropsWithChildren<{
  departure?: Departure
}>): React.ReactNode {
  if (departure !== undefined) {
    return (
      <DepartureProvider departure={departure}>{children}</DepartureProvider>
    )
  }
  return children
}

export default function SelectShip (): JSX.Element {
  const { date: dateString } = useParams()
  const { t } = useTranslation()
  const { tour } = useContext(TourContext)
  const navigate = useNavigate()
  const [selectedDeparture, setSelectedDeparture] = useState<Departure | undefined>(undefined)
  const [showError, setShowError] = useState(false)
  const [searchParams] = useSearchParams()
  const partySize = z.coerce.number().nullable().parse(searchParams.get('party'))

  const date = useMemo(() => dayjs(dateString), [dateString])

  const { data: departuresData = [], isSuccess, isLoading } = useDepartures(tour.id, date)
  const departures = useMemo(() => {
    return filterDepartures(departuresData, dateString ?? '', partySize)
  }, [partySize, dateString, departuresData])

  useEffect(() => {
    if (departures.length === 1 && selectedDeparture === undefined) {
      setSelectedDeparture(departures[0])
    }
  }, [departures, selectedDeparture])

  const handleNext = useCallback(() => {
    if (selectedDeparture !== undefined) {
      const searchParams = new URLSearchParams()
      if (partySize != null) {
        searchParams.set('party', String(partySize))
      }
      if (tour.tours.length > 1) {
        searchParams.set('tours', tour.tours.map(tour => tour.id).join(':'))
      }
      navigate(`../../departures/${selectedDeparture.id}/cabins${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`)
    } else {
      setShowError(true)
    }
  }, [navigate, partySize, selectedDeparture, tour.tours])

  const handleBack = useCallback(() => {
    const params = new URLSearchParams()
    if (dateString !== undefined) {
      params.set('date', dateString)
    }
    if (partySize != null) {
      params.set('party', String(partySize))
    }
    navigate(`../../tours/${tour.id}${params.size > 0 ? '?' : ''}${params.toString()}`)
  }, [dateString, navigate, partySize, tour.id])

  usePreloadQuery(CabinTranslationsQuery(selectedDeparture?.ship_id ?? ''))
  usePreloadQuery(ProductTranslationsQuery())

  return (
    <>
      <BookingProgress currentStep={2} />
      <TwoColumnLayout>
        <section>
          <h2 className='text-brand-primary text-lg font-bold mb-4'>{t('choose_preferred_ship', 'Choose preferred ship', { count: departures.length })}</h2>
          {isLoading && <Loader />}
          {isSuccess && departures.length === 0 && (<p>{t('no_ship_available', 'No ship available.')}</p>)}
          <WithError anchor='top' className='flex flex-col gap-8 rounded' error={showError ? t('choose_preferred_ship', 'Choose preferred ship') : null} clearError={() => setShowError(false)}>
            {departures.map(departure => (
              <ShipCard
                selected={selectedDeparture?.id === departure.id}
                showSelected={departures.length > 1}
                departure={departure}
                key={departure.id}
                onSelect={setSelectedDeparture}
              />
            ))}
          </WithError>
        </section>
        <OptionalDepartureProvider departure={selectedDeparture}>
          <Sidebar
            onBack={handleBack}
            onNext={handleNext}
          />
        </OptionalDepartureProvider>
      </TwoColumnLayout>
    </>
  )
}

import { Departure } from '../services/departures'

export function filterDepartures (departures: Departure[], date: string, partySize: number | null | undefined): Departure[] {
  return departures.filter(departure => {
    return departure.date === date && departure.availability != null && (
      partySize == null || (
        departure.availability.minimum_passengers <= partySize &&
          departure.availability.maximum_passengers >= partySize
      )
    )
  })
}

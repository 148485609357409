import { PropsWithChildren } from 'react'

export default function NotFound ({ children }: PropsWithChildren<{}>): React.ReactNode {
  return (
    <div className='container mx-auto'>
      <h1 className='my-8 text-3xl font-bold'>Not found</h1>
      {children ?? <p>This page does not exist.</p>}
    </div>
  )
}

import { Market } from '../types/booking'
import { usePriceTotal } from '../hooks/products'
import { useContext, useMemo } from 'react'
import { ProductsContext } from '../contexts/products'
import InsuranceProduct from './insurance-product'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { OverviewValues } from '../types/overview'

interface InsuranceProductsProps {
  market: Market
  additionalProducts: Record<string, number>
  noInsurance: boolean
}

export default function InsuranceProducts ({ market, additionalProducts, noInsurance }: InsuranceProductsProps): JSX.Element | undefined {
  const { t } = useTranslation()
  const { products } = useContext(ProductsContext)
  const { values: { insurance } } = useFormikContext<OverviewValues>()

  const total = usePriceTotal({
    bikes: true,
    diets: true,
    other: true,
    discount: true,
    cabins: true,
    additionalProducts
  })
  const { totalPrice, localCosts } = total

  const insurancePolicies = useMemo(() => products.insurance_policies.filter(policy => policy.market === market && !noInsurance), [market, noInsurance, products.insurance_policies])
  const selectedInsurancePolices = useMemo(() => insurancePolicies.filter(policy => insurance[policy.insurance_type]), [insurance, insurancePolicies])

  if (insurancePolicies.length === 0) {
    return undefined
  }

  return (
    <div className='mb-4'>
      <h2 className='text-brand-primary text-lg font-bold mb-4'>{t('insurance:policies', 'Insurance policies')}</h2>
      <div>
        {insurancePolicies.map(policy => (
          <InsuranceProduct key={policy.id} policy={policy} totalPrice={totalPrice} localCosts={localCosts} />
        ))}
      </div>
      {selectedInsurancePolices.length === 0 && (
        <div
          className='mt-4 px-8 py-4 bg-brand-light border-brand-primary border'
          dangerouslySetInnerHTML={{ __html: t('insurance:no_insurance') }}
        />
      )}
    </div>
  )
}

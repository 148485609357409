import { Departure } from '../../services/departures'
import { useTranslation } from 'react-i18next'
import OverviewBlock from './block'
import { useMemo } from 'react'
import dayjs from '../../libs/dayjs'

interface DepartureSectionOptions {
  departure: Departure
}

export default function DepartureSection ({ departure }: DepartureSectionOptions): JSX.Element {
  const { t, i18n } = useTranslation()
  const unknown = t('unknown', 'Unknown')

  const departureDate = useMemo(() => {
    const dateFormat = t('date_format', 'L')
    const start = dayjs(departure.date).locale(i18n.resolvedLanguage ?? 'en')
    const end = dayjs(departure.end_date).locale(i18n.resolvedLanguage ?? 'en')
    return `${start.format(dateFormat)} - ${end.format(dateFormat)}`
  }, [departure.date, departure.end_date, i18n.resolvedLanguage, t])

  return (
    <>
      <OverviewBlock label={t('summary:departure', 'Departure')} value={`${departure.port_of_departure_name ?? unknown} - ${departure.port_of_arrival_name ?? unknown}`} />
      <OverviewBlock label={t('summary:date', 'Date')} value={departureDate} />
      <OverviewBlock label={t('summary:ship', 'Ship')} value={departure.ship_name} />
      <OverviewBlock label={t('summary:port_of_departure', 'Port of departure')} value={departure.port_of_departure_name} />
    </>
  )
}

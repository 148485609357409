import { Outlet, useParams } from 'react-router-dom'
import { usePayment } from '../hooks/payments'
import Loader from '../components/loader'
import PaymentProvider from '../contexts/payment'
import NotFound from './not-found'
import { ZodError } from 'zod'
import Container from '../components/container'

export default function PaymentPage (): JSX.Element {
  const { payment: paymentId = '' } = useParams()

  const { data: payment, isLoading, isSuccess, isError, error } = usePayment(paymentId)

  if (isLoading) {
    return (<Loader />)
  }

  if (isSuccess) {
    if (payment === null) {
      return (<NotFound />)
    }
    return (<PaymentProvider payment={payment}><Outlet /></PaymentProvider>)
  }

  if (isError && error instanceof ZodError) {
    return (
      <Container>
        <p className='my-8'>This payment cannot be completed at this time.</p>
      </Container>
    )
  }
  return (<Container><p className='my-8'>Something went wrong.</p></Container>)
}

class ServiceException extends Error {
  response?: Response
  jsonPromise?: Promise<unknown>
  textPromise?: Promise<string>

  constructor (message: string, response?: Response) {
    super(message)
    this.response = response
    if (response !== undefined) {
      // This part is to make sure Chrome shows the response data in
      // the network tab of the dev tools
      if ((response.headers.get('content-type') ?? '').includes('application/json')) {
        this.jsonPromise = response.json()
      } else {
        this.textPromise = response.text()
      }
    }
  }
}

export class ClientException extends ServiceException {}
export class ServerException extends ServiceException {}
export class NotFoundException extends ClientException {}
export class InternalServerError extends ServerException {}

const COMMON_ERRORS: Record<number, typeof ServiceException> = {
  404: NotFoundException,
  500: InternalServerError
}

export default class Service {
  base: string

  constructor (base = '') {
    this.base = base
  }

  async request<T = unknown> (method: string, path: string, headers?: HeadersInit, body: BodyInit | null | undefined = undefined): Promise<T> {
    const response = await fetch(`${this.base}${path}`, {
      method,
      headers,
      body
    })
    if (response.ok) {
      return await response.json()
    }

    if (COMMON_ERRORS[response.status] !== undefined) {
      throw new COMMON_ERRORS[response.status](response.statusText, response)
    }

    if (response.status >= 400 && response.status <= 499) {
      throw new ClientException(response.statusText, response)
    }
    if (response.status >= 500) {
      throw new ServerException(response.statusText, response)
    }

    return await response.json()
  }

  async get<T = unknown> (path: string, params?: Record<string, string>, headers?: HeadersInit): Promise<T> {
    if (params != null) {
      const searchParams = new URLSearchParams(params)
      path += '?' + searchParams.toString()
    }

    return await this.request('GET', path, headers)
  }

  async post<T = unknown> (path: string, body: BodyInit | Record<string, unknown>, params?: Record<string, string>, headers?: HeadersInit): Promise<T> {
    if (params != null) {
      const searchParams = new URLSearchParams(params)
      path += '?' + searchParams.toString()
    }

    if (typeof body === 'object' && body != null && !(body instanceof Blob || body instanceof FormData)) {
      body = JSON.stringify(body)
      if (headers !== undefined) {
        if (headers instanceof Headers) {
          headers.set('content-type', 'application/json')
        } else if (Array.isArray(headers)) {
          headers.push(['content-type', 'application/json'])
        } else {
          headers['content-type'] = 'application/json'
        }
      } else {
        headers = { 'content-type': 'application/json' }
      }
    }

    return await this.request('POST', path, headers, body)
  }
}
